import React, { useEffect } from "react";

import Hero from "@components/shared/hero";
import { Container } from "@util/standard";
import CardContentBlock from "@sub/cardContentBlock";
import BlocksContent from "@components/shared/sub/blockContent";
import styled from "styled-components";
import SEO from "@components/shared/seo";
import { TABLET_BREAKPOINT } from "@util/constants";
import LayOut from "@components/layout";
import { Query } from "@graphql-types";
import { PageProps, graphql } from "gatsby";

const StyledBlocksContent = styled(Container)`
  width: 50%;
  margin: auto;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 40px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 90%;
  }
`;

interface Props extends PageProps {
  data: Query;
}

const Security = (props: Props) => {
  const sanitySecurityCompliancePage =
    props.data.allSanitySecurityCompliancePage.nodes[0];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO seoData={sanitySecurityCompliancePage.seo} />

      <LayOut>
        <Hero singleHeroContent={sanitySecurityCompliancePage.hero} />
        <Container width="100%" backgroundColor="babyBlue">
          {sanitySecurityCompliancePage.complianceHeader && (
            <StyledBlocksContent>
              <BlocksContent
                blocks={
                  sanitySecurityCompliancePage?.complianceHeader._rawContent
                }
              />
            </StyledBlocksContent>
          )}
          {sanitySecurityCompliancePage?.complianceBadges && (
            <Container
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              gridGap="30px"
              mobileGridGap="0px"
              justifyContent="center"
              paddingBottom={90}
              maxWidth="1440px"
              margin="auto"
            >
              {sanitySecurityCompliancePage.complianceBadges.map(badge => {
                return (
                  <CardContentBlock
                    cardImage={badge?.cardImage}
                    cardTitle={badge?.cardTitle}
                    cardText={badge?.cardText}
                    cardLink={badge?.cardLink}
                    key={badge?._key}
                  />
                );
              })}
            </Container>
          )}
        </Container>
      </LayOut>
    </>
  );
};

export default Security;

export const query = graphql`
  query securiyCompliance($iban: String) {
    allSanitySecurityCompliancePage(
      filter: { region: { iban: { eq: $iban } } }
    ) {
      nodes {
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        complianceHeader {
          _rawContent
        }
        complianceBadges {
          ...sanityCardContentBlock
        }
      }
    }
  }
`;
